import React from "react"
import { css } from "@emotion/core"

import ImageResolver from "../ImageResolver"
import Curve from "../Curve"

export default function Trust({ data }) {
  const items = data.items

  return (
    <>
      {" "}
      <div className="trust label-id" data-label={data.slice_label}>
        <div className="container pt-10 pb-16  ">
          {items && (
            <div className="flex flex-wrap items-center justify-center phone:justify-start  ">
              {items.map((item) => {
                return (
                  <div
                    className="grid items-center md:mx-10 my-4"
                    css={css`
                      grid-template-columns: 40px auto;
                      grid-gap: 20px;
                    `}
                  >
                    <div>
                      <ImageResolver image={item.icon} />
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.text.html }}
                    ></div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <Curve className="text-white" />
    </>
  )
}
