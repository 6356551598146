import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import cn from "classnames"

import FormSelector from "@/components/FormSelector"
import ImageResolver from "../ImageResolver"

export default function HeaderV2({ data, pageContext }) {
  const inverse = data.primary.inverse
  const form = get(data, "primary.form.document.data")
  return (
    <div
      css={
        inverse
          ? css`
              ${tw`bg-light`}
              &+ .trust {
                ${tw`bg-light`}
              }
            `
          : css`
              .c {
                ${tw`bg-light hover:bg-white`}
              }
            `
      }
    >
      <div
        className={cn("overflow-hidden relative  label-id ")}
        data-label={data.slice_label}
      >
        <div className={cn("container phone:pt-32 pt-40 ")}>
          <div>
            <div
              className={cn("    mb-12 mx-auto")}
              css={[
                css`
                  h1 {
                    ${tw`text-4xl`}
                  }
                  p {
                    font-size: 18px;
                    color: #000;
                  }
                  strong {
                    ${tw` font-bold`}
                  }

                  img {
                    max-width: 150px;
                    margin: 0 !important;
                  }
                `,
                data.primary.collapse_on_mobile &&
                  css`
                    .primary-button {
                      ${tw`md:hidden w-full text-xl py-3 max-w-md`}
                    }
                  `,
              ]}
            >
              <div className="row">
                <div className="col  w-full lg:w-8/12   ">
                  <div
                    className="prose"
                    css={css`
                      & ul > li {
                        ${tw`text-lg text-black`}
                      }
                    `}
                  >
                    {" "}
                    <TextRender text={get(data, "primary.text.raw")} />
                  </div>
                  {data.items && (
                    <div className="flex flex-wrap items-center ">
                      {data.items.map(({ logo }) => {
                        if (!logo.fluid) {
                          return null
                        }
                        const maxWidth =
                          logo.dimensions.width > 90
                            ? 90
                            : logo.dimensions.width
                        return (
                          <div
                            className="w-full mr-4 md:mr-6  my-3 md:my-4"
                            css={css`
                              max-width: 50px;
                              @media (min-width: 1024px) {
                                max-width: 75px;
                              }
                              img {
                                max-width: 50px;
                                @media (min-width: 1024px) {
                                  max-width: 75px;
                                }
                              }
                            `}
                          >
                            <ImageResolver image={logo} width={maxWidth} />
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
                <div className="col w-full lg:w-4/12  tablet:mt-8 ">
                  <div
                    className="bg-white rounded p-4 filter-shadow"
                    css={css`
                      .primary-button {
                        ${tw`w-full`}
                      }
                    `}
                  >
                    <div
                      className=" mb-4"
                      css={css`
                        p {
                          ${tw`text-lg font-semibold`}
                        }
                      `}
                    >
                      <TextRender text={get(form, "title.raw")} />
                    </div>
                    <div
                      className={cn({
                        "phone:px-5": form.type === "Custom Quote",
                      })}
                      css={css`
                        .primary-button {
                          ${tw`text-base!`}
                        }
                      `}
                    >
                      {form && (
                        <FormSelector
                          type={form.type}
                          data={form}
                          lang={pageContext.lang}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  )
}
