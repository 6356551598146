import React from "react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { css } from "@emotion/core"
import TextRender from "../TextRender"
import tw from "twin.macro"
import { get } from "react-hook-form"
import { Elements } from "prismic-reactjs"
import { useStaticQuery } from "gatsby"

export default function Themes({ data }) {
  const items = data.items
  const flags = useStaticQuery(graphql`
    {
      fr: file(relativePath: { regex: "/flags/fr.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      en: file(relativePath: { regex: "/flags/en.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      it: file(relativePath: { regex: "/flags/it.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      de: file(relativePath: { regex: "/flags/de.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      {" "}
      <div className=" py-16 lg:py-20 label-id" data-label={data.slice_label}>
        <div className="container">
          <div
            css={css`
              h1,
              h2 {
                ${tw`text-3xl lg:text-4xl `}
              }
              h3 {
                ${tw`text-xl lg:text-2xl`}
              }
            `}
          >
            <TextRender text={data.primary.text.raw} />
          </div>
          <div className="row items-center">
            <div className="col w-full lg:w-3/5 tablet:mb-6">
              <TextRender
                text={get(data, "primary.list.raw")}
                serializer={(type, element, content, children, key) => {
                  switch (type) {
                    case Elements.list:
                      return (
                        <div className="flex flex-wrap my-10">{children}</div>
                      )
                    case Elements.listItem:
                      return (
                        <div className="mr-3 border border-primary px-5 py-2 rounded leading-none my-1  text-sm font-semibold">
                          {children}
                        </div>
                      )

                    default:
                      break
                  }
                }}
              />
            </div>
            <div className="col w-full lg:w-2/5 space-y-5  flex flex-col justify-center items-stretch">
              <GatsbyImage fluid={get(data, "primary.image.fluid")} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
