import React from "react"
import ImageResolver from "../ImageResolver"

export default function Logos({ data }) {
  const items = data.items

  return (
    <div className="Logos label-id" data-label={data.slice_label}>
      <div className="container pt-6 pb-12 ">
        {items && (
          <div className="flex flex-wrap items-center justify-between phone:justify-center">
            {items.map(({ logo }) => {
              if (!logo.fluid) {
                return null
              }
              const maxWidth =
                logo.dimensions.width > 75 ? 75 : logo.dimensions.width
              return (
                <div className="w-full mx-4 my-4" style={{ maxWidth }}>
                  <ImageResolver image={logo} />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
