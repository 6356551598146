import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import Curve from "../Curve"
import cn from "classnames"
import GatsbyImage from "gatsby-image"
import { Elements } from "prismic-reactjs"
import { FaCheck } from "react-icons/fa"
import tw from "twin.macro"
import { css } from "@emotion/core"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Slider from "react-slick"
import ImageProps from "../ImageProps"

var settings = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export default function Testimonials({ data }) {
  const items = data.items
  return (
    <>
      <Curve className="text-light" />
      <div className="p-12 bg-light label-id" data-label={data.slice_label}>
        <div className="container py-12">
          <div
            className="mx-auto   text-center"
            css={css`
              ${tw`text-3xl lg:text-4xl`}
            `}
          >
            <TextRender text={get(data, "primary.text.raw")} />
          </div>

          {items && (
            <div
              css={css`
                .slick-list {
                  ${tw`p-4 -mx-4`}
                }
                .slick-slide div {
                  outline: none;
                }
                ${tw`mt-12  lg:mt-20`} .slick-track {
                  ${tw`-mx-4 `}
                  cursor: ew-resize;
                  > div {
                    ${tw`px-4`}
                  }
                }
                .slick-dots {
                  ${tw`-bottom-10`}
                }
                .slick-dots {
                  li {
                    ${tw`m-0`}
                  }
                  li button:before {
                    content: "";
                    width: 10px;
                    height: 8px;
                    ${tw`transition-all duration-300 opacity-100 bg-primary`};
                  }
                  li.slick-active button:before {
                    ${tw`transform rotate-45`}
                  }
                }
              `}
            >
              <Slider {...settings}>
                {items.map((item, index) => {
                  return (
                    <div
                      css={css`
                        display: grid !important;
                        grid-column-gap: 10px;
                        grid-template-columns: 54px 1fr;
                      `}
                      key={index}
                    >
                      {item.image.fixed && (
                        <GatsbyImage
                          css={css`
                            filter: drop-shadow(
                              0px 4px 4px rgba(0, 0, 0, 0.25)
                            );
                          `}
                          {...ImageProps(item.image)}
                          className="overflow-hidden rounded-full "
                          fixed={item.image.fixed}
                        />
                      )}
                      <div
                        className="relative p-4 bg-white rounded-sm"
                        css={css`
                          filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
                        `}
                      >
                        <div className="prose max-w-none rotate">
                          <TextRender
                            text={get(item, "text.raw")}
                            serializer={serializer}
                          />
                          <div className="text-xs">
                            - <span className="text-primary">{item.name}</span>{" "}
                            {item.position}
                          </div>
                          <div
                            css={css`
                              position: absolute;
                              ${tw` top-5 -left-1.5 block w-3 h-3 bg-white transform rotate-45  rounded-bl-sm`}
                            `}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function serializer(type, element, content, children, key) {
  switch (type) {
    case Elements.list:
      return <ul className="mb-6">{children}</ul>
    case Elements.listItem:
      return (
        <li
          tw="relative"
          css={css`
            &:before {
              display: none !important;
            }
          `}
        >
          <FaCheck tw="absolute top-1.5 left-0 mt-0! text-primary" /> {children}
        </li>
      )
      return null
    default:
      return null
  }
}
