import React, { useState } from "react"
import get from "lodash/get"
import Collapse from "../Collapse"
import { css } from "@emotion/core"
import tw from "twin.macro"

import TextRender from "../TextRender"
import { FaChevronDown } from "react-icons/fa"
import cn from "classnames"
import ImageResolver from "../ImageResolver"

export default function Accordion({ data }) {
  const items = data.items
  const hasImage = get(data, "primary.image.fluid")
  return (
    <div
      className="my-10 lg:my-20 container label-id"
      data-label={data.slice_label}
    >
      {!hasImage && (
        <h2 className="text-center mb-8  text-3xl lg:text-4xl">
          {get(data, "primary.title1.text")}
        </h2>
      )}
      <div
        css={
          hasImage &&
          css`
            ${tw`grid gap-8 lg:grid-cols-2`}
          `
        }
      >
        {get(data, "primary.image.fluid") && (
          <div>
            <ImageResolver image={data.primary.image} />
          </div>
        )}
        <div>
          {hasImage && (
            <h2 className=" mb-8 text-3xl lg:text-4xl">
              {get(data, "primary.title1.text")}
            </h2>
          )}
          {items &&
            items.map((item, index) => {
              return <Item key={index} item={item} />
            })}
        </div>
      </div>
    </div>
  )
}

function Item({ item }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="mt-4">
      <div
        className="filter-shadow p-4 bg-light rounded  flex items-center justify-between cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <span>{item.title1}</span>
        <FaChevronDown
          className={cn(
            "w-3 h-3 transition-transform duration-300 transform ease-in-out",
            {
              "rotate-180": isOpen,
              "rotate-0": !isOpen,
            }
          )}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="prose pt-3 px-4 max-w-none">
          <TextRender text={get(item, "text.raw")} />
        </div>
      </Collapse>
    </div>
  )
}
