import React from "react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { css } from "@emotion/core"
import TextRender from "../TextRender"
import tw from "twin.macro"
import { get } from "react-hook-form"
import { Elements, RichText } from "prismic-reactjs"
import { useStaticQuery } from "gatsby"

export default function Prices({ data }) {
  const items = data.items
  const flags = useStaticQuery(graphql`
    {
      fr: file(relativePath: { regex: "/flags/fr.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      en: file(relativePath: { regex: "/flags/en.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      it: file(relativePath: { regex: "/flags/it.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      de: file(relativePath: { regex: "/flags/de.png/" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      {" "}
      <div
        className=" py-16 lg:py-20 bg-light label-id"
        data-label={data.slice_label}
      >
        <div className="container">
          <div className="row items-center">
            <div className="col w-full lg:w-2/5 tablet:mb-6">
              <div
                css={css`
                  h2 {
                    ${tw`text-3xl lg:text-4xl mb-8`}
                  }
                  h3 {
                    ${tw`text-xl lg:text-2xl`}
                  }
                `}
              >
                {" "}
                <TextRender text={data.primary.text.raw} />
              </div>
            </div>
            <div className="col w-full lg:w-3/5 space-y-5  flex flex-col justify-center items-stretch">
              {items &&
                items.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" relative rounded flex bg-white filter-shadow"
                    >
                      <div className="flex-grow p-3">
                        <h4 className="text-primary font-bold mb-3">
                          {RichText.asText(item.title.raw)}
                        </h4>
                        <div
                          className="text-sm grid gap-x-4 gap-y-0.5    "
                          css={css`
                            @media (min-width: 640px) {
                              grid-template-columns: repeat(2, max-content);
                            }
                            p {
                              ${tw`mr-4 flex justify-start items-center`}
                              strong {
                                ${tw`mr-3`}
                              }
                              .gatsby-image-wrapper {
                                ${tw`ml-2`}
                              }
                            }
                          `}
                        >
                          <span className="bg-primary filter-shadow absolute text-white font-bold px-5 py-1 leading-none rounded top-2 right-0 z-10 transform rotate-45 translate-x-1/3">
                            {item.price}
                          </span>
                          <TextRender
                            text={item.details.raw}
                            serializer={(
                              type,
                              element,
                              content,
                              children,
                              key
                            ) => {
                              switch (type) {
                                case Elements.label:
                                  switch (element.data.label) {
                                    case "de-flag":
                                      return (
                                        <GatsbyImage
                                          fixed={flags.de.childImageSharp.fixed}
                                        />
                                      )
                                    case "en-flag":
                                      return (
                                        <GatsbyImage
                                          fixed={flags.en.childImageSharp.fixed}
                                        />
                                      )
                                    case "fr-flag":
                                      return (
                                        <GatsbyImage
                                          fixed={flags.fr.childImageSharp.fixed}
                                        />
                                      )
                                    case "it-flag":
                                      return (
                                        <GatsbyImage
                                          fixed={flags.it.childImageSharp.fixed}
                                        />
                                      )

                                    default:
                                      break
                                  }
                                  break

                                default:
                                  break
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="flex-shrink-0"
                        css={css`
                          @media (max-width: 640px) {
                            width: 90px;
                          }
                          width: 120px;
                          overflow: hidden;
                          position: relative;

                          > * {
                            height: 100%;
                          }
                        `}
                      >
                        <GatsbyImage fluid={get(item, "image.fluid")} />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
