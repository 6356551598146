import { graphql } from "gatsby"
import React from "react"
import get from "lodash/get"
import Layout from "../components/Layout"
import Logos from "../components/landingpage/Logos"
import Testimonials from "../components/landingpage/Testimonials"
import Accordion from "../components/landingpage/Accordion"
import HeaderV2 from "../components/landingpage/HeaderV2"
import Trust from "../components/landingpage/Trust"
import Prices from "../components/landingpage/Prices"
import Themes from "../components/landingpage/Themes"

export default function LandingPage({ data, pageContext }) {
  const slices = get(data, "prismicLandingPage.data.body")
  return (
    <Layout
      scrollArrowAction={get(
        data,
        "prismicLandingPage.data.scroll_arrow_action"
      )}
      showMenu={get(data, "prismicLandingPage.data.show_menu")}
      pageContext={pageContext}
      landingpage={get(data, "prismicLandingPage.data.navigation_items")}
      seo={get(data, "prismicLandingPage.seo")}
    >
      <SliceMachine slices={slices} pageContext={pageContext} />
    </Layout>
  )
}

function SliceMachine({ slices, pageContext }) {
  return (
    slices &&
    slices.map((slice, index) => {
      switch (slice.__typename) {
        case "PrismicLandingPageBodyHeaderV2":
          return <HeaderV2 key={index} data={slice} pageContext={pageContext} />
        case "PrismicLandingPageBodyLogos":
          return <Logos key={index} data={slice} />
        case "PrismicLandingPageBodyTestimonials":
          return <Testimonials key={index} data={slice} />
        case "PrismicLandingPageBodyAccordion":
          return <Accordion key={index} data={slice} />
        case "PrismicLandingPageBodyPrices":
          return <Prices key={index} data={slice} />
        case "PrismicLandingPageBodyThemes":
          return <Themes key={index} data={slice} />
        case "PrismicLandingPageBodyTrust":
          return <Trust key={index} data={slice} lang={pageContext.lang} />

        default:
          return (
            <div className="overflow-x-scroll" key={index}>
              <pre>{JSON.stringify(slice, null, 2)}</pre>
            </div>
          )
      }
    })
  )
}

export const query = graphql`
  query($uid: String!, $lang: String) {
    prismicLandingPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      seo: data {
        meta_title
        meta_description
        meta_keywords
        meta_image {
          url
        }
      }
      data {
        show_menu
        scroll_arrow_action {
          raw
        }
        navigation_items {
          link {
            raw
          }
        }
        body {
          __typename

          ... on PrismicLandingPageBodyLogos {
            slice_label
            id
            items {
              logo {
                dimensions {
                  width
                  height
                }
                url
                alt
                fluid(imgixParams: { q: 100, w: 1 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }

          ... on PrismicLandingPageBodyTestimonials {
            slice_label
            id
            primary {
              text {
                raw
              }
            }
            items {
              position
              name
              text {
                raw
              }
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fixed(width: 54, height: 54) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }

          ... on PrismicLandingPageBodyAccordion {
            slice_label
            id
            primary {
              title1 {
                text
              }
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fluid(imgixParams: { maxW: 800 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            items {
              title1
              text {
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyThemes {
            slice_label
            primary {
              text {
                raw
              }
              list {
                raw
              }

              image {
                fluid(imgixParams: { maxW: 800 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                dimensions {
                  width
                  height
                }
                alt
              }
            }
          }
          ... on PrismicLandingPageBodyPrices {
            slice_label
            primary {
              text {
                raw
              }
            }
            items {
              price
              title: title1 {
                raw
              }
              image {
                fluid(maxWidth: 120) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                dimensions {
                  width
                  height
                }
                alt
              }
              details {
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyTrust {
            slice_label
            items {
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              text {
                html
              }
            }
          }
          ... on PrismicLandingPageBodyHeaderV2 {
            slice_label
            id
            primary {
              inverse
              collapse_on_mobile

              text {
                raw
              }
              form {
                document {
                  ... on PrismicRegistrationForm {
                    id
                    data {
                      ...RegistrationFormData
                    }
                  }
                }
              }
            }
            items {
              logo {
                dimensions {
                  width
                  height
                }
                url
                alt
                fluid(imgixParams: { q: 100, w: 1 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
